import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import "./_header.scss"

import Logo from "../images/logo.svg"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navOpen: false,
    }
  }

  navToggle = () => {
    if (this.state.navOpen === false) {
      this.setState({ navOpen: true })
    } else {
      this.setState({ navOpen: false })
    }
  }

  render() {
    let mobileNavClasses = ""
    let navBtnClasses = ""
    if (this.state.navOpen) {
      mobileNavClasses = "mobile-nav open"
      navBtnClasses = "btn btn-outline btn-outline-blue nav-btn open"
    } else {
      mobileNavClasses = "mobile-nav"
      navBtnClasses = "btn btn-outline btn-outline-blue nav-btn"
    }
    return (
      <header className="site-header">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt={this.props.siteTitle} />
          </Link>
        </div>
        <div className="site-nav">
          <button onClick={this.navToggle} className={navBtnClasses}>
            <div></div>
            <div></div>
            <div></div>
          </button>
          <div className={mobileNavClasses}>
            <nav className="nav">
              <ul>
                <li>
                  <AnchorLink to="/#how-it-works">How It Works</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/#rides">Rides</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/#about">About</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/#lets-move">Download</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/#advertise-with-us">
                    Advertise with us
                  </AnchorLink>
                </li>
                {/* <li>
                  <Link to="/" className="btn btn-outline">
                    Become Partner
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
