/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import FooterLogo from "../images/footer-logo.svg"

import AppleIcon from "../images/apple-icon.svg"
import GooglePlayIcon from "../images/google-play-icon.png"

import YTIcon from "../images/yt.svg"
import FBIcon from "../images/facebook.svg"
import TwitterIcon from "../images/twitter.svg"

import Header from "./header"
import "./layout.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <footer className="site-footer">
          <div className="container">
            <div className="footer-main">
              <div className="row">
                <div className="footer-nav col">
                  <img src={FooterLogo} alt="" />
                  <nav className="nav">
                    <ul>
                      <li>
                        <AnchorLink to="/#rides">Rides</AnchorLink>
                      </li>
                      <li>
                        <AnchorLink to="/#about">About</AnchorLink>
                      </li>
                      <li>
                        <AnchorLink to="/#lets-move">Download</AnchorLink>
                      </li>
                      <li>
                        <AnchorLink to="/#advertise-with-us">
                          Advertise with us
                        </AnchorLink>
                      </li>
                      
                    </ul>
                  </nav>
                  <nav className="nav lite">
                    <ul >
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms-of-use">Terms of Use</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col">
                  <div className="download-cta">
                    <div className="btn-group">
                      <a href="#" className="btn btn-apple">
                        <img src={AppleIcon} />
                        <div>
                          Get it on
                          <span>App Store</span>
                        </div>
                      </a>
                      <a href="#" className="btn btn-play-store">
                        <img src={GooglePlayIcon} />
                        <div>
                          Get it on
                          <span>Google Play</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-copyright">
              <div className="row">
                <div className="col">
                  <p>Copyright 2020 - 2021 © Move</p>
                </div>
                <div className="col">
                  <div className="social-media-nav">
                    <a href="#">
                      <img src={YTIcon} alt="" />
                    </a>
                    <a href="#">
                      <img src={FBIcon} alt="" />
                    </a>
                    <a href="#">
                      <img src={TwitterIcon} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
